<template>
  <div
    class="modal fade"
    id="documentActionModal"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
    ref="documentsActionModal"
  >
    <div class="modal-dialog">
      <Form :validation-schema="validationSchema" @submit="submit">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalSmall">{{ title }} document</h5>
          </div>
          <div class="modal-body">
            <div class="row mb-5">
              <div class="col-md-12 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Name</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  ref="form"
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter name"
                  name="name"
                  v-model="data.name"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-12">
                <!--begin::Label-->
                <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span class="required">Document type</span>
                </label>
                <!--end::Label-->
                <DocumentTypeDropdown
                  :name="'type_id'"
                  :initialValue="data.type_id"
                  @input="
                    id => {
                      data.type_id = id;
                    }
                  "
                ></DocumentTypeDropdown>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="type_id" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12">
                <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span>Expiration</span>
                </label>
                <el-date-picker
                  style="width:100%"
                  size="large"
                  v-model="data.expiration"
                  format="DD/MM/YYYY"
                  value-format="DD/MM/YYYY"
                ></el-date-picker>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12">
                <label
                  for="formFile"
                  class="d-flex align-items-center fs-5 fw-bold mb-2 required"
                  >Upload document(Front)</label
                >
                <input class="form-control" type="file" id="formFile" />
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12">
                <label
                  for="formFile"
                  class="d-flex align-items-center fs-5 fw-bold mb-2"
                  >Upload document(back)</label
                >
                <input class="form-control" type="file" id="formFile" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="$emit('cancel')"
            >
              Close
            </button>
            <button type="submit" class="btn btn-primary" aria-label="Close">
              Submit
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import DocumentTypeDropdown from "@/components/dropdown/VeeDocumentTypeDropdown.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';

function initialDocumentData() {
  return {
    name: "",
    type_id: "",
    expiration: ""
  }
};


export default defineComponent({
  name: "Documents-Action-Modal",
  components: {
    ErrorMessage,
    Field,
    Form,
    DocumentTypeDropdown
  },
  props: {
    title: String,
    selectedDocument: Object
  },
  data() {
    return {
      data: initialDocumentData(),
      modal: null,
      validationSchema: Yup.object().shape({
        name: Yup.string()
        .required()
        .label("Name"),
        type_id: Yup.string()
        .required()
        .label("Document type")
      })
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs.documentsActionModal)
  },
  watch: {
    selectedDocument() {
      if(this.title == "Edit") {
        this.data.name = this.selectedDocument.name;
        this.data.type_id = this.selectedDocument.type_id;
        this.data.expiration = this.selectedDocument.expiration;
      } else {
        this.data = initialDocumentData()
     }
    }
  },
  methods: {
    submit() {
      this.validationSchema.isValid(this.data).then((validationResult) => {
        if(validationResult){
          this.modal.hide();
          this.$emit("submitData", {payload : this.data , action : this.title});
        }
      })
    }
  }
});
</script>
