<template>
  <DocumentsActionModal
    :title="modalTitle"
    :selectedDocument="currentlySelectedDocument"
    @submitData="handleModalSubmit"
  ></DocumentsActionModal>
  <DeleteModal
    :currentlySelectedEntry="currentlySelectedDocument.name"
    @delete="deleteDocument"
  ></DeleteModal>
  <LoadingSpinner v-if="loading"></LoadingSpinner>
  <div class="card card-flush" v-else>
    <div class="card-header pt-8 justify-content-end">
      <div class="d-flex">
        <div class="d-flex">
          <button
            type="button"
            class="btn btn-primary"
            @click="openAddNewModal()"
            data-bs-toggle="modal"
            data-bs-target="#documentActionModal"
          >
            <span class="svg-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                  fill="black"
                ></path>
                <path
                  d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z"
                  fill="black"
                ></path>
                <path
                  opacity="0.3"
                  d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z"
                  fill="black"
                ></path>
              </svg>
            </span>
            Upload document
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        >
          <thead>
            <tr
              class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
            >
              <th class="min w-140px">
                Document name
              </th>
              <th class="min-w-100px">
                Type
              </th>
              <th class="min-w-100px">
                Status
              </th>
              <th class="min w-150px">Expiration</th>
              <th class="min w-200px text-end">Actions</th>
            </tr>
          </thead>
          <tbody class="fw-bold text-gray-600">
            <tr v-for="document in getDocuments" :key="document.id">
              <td>
                <div class="d-flex align-items-center">
                  <span class="svg-icon svg-icon-2x svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                        fill="black"
                      ></path>
                      <path
                        d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                  <a
                    target="_blank"
                    :href="document.file_link"
                    class="text-gray-800 text-hover-primary"
                    >{{ document.name }}</a
                  >
                </div>
              </td>
              <td>{{ document.type.name }}</td>
              <td>{{ document.status }}</td>
              <td>{{ document.expiration }}</td>
              <td class="text-end">
                <div class="d-flex justify-content-end">
                  <div class="ms-2">
                    <a
                      target="_blank"
                      :href="document.file_link"
                      type="button"
                      class="btn btn-sm btn-icon btn-light btn-active-light-primary"
                    >
                     <ViewButtonIcon></ViewButtonIcon>
                    </a>
                  </div>

                  <div class="ms-2">
                    <button
                      type="button"
                      class="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                      @click="openEditModal(document)"
                      data-bs-toggle="modal"
                      data-bs-target="#documentActionModal"
                    >
                      <EditButtonIcon></EditButtonIcon>
                    </button>
                  </div>
                  <div class="">
                    <button
                      type="button"
                      class="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                      @click="openDeleteModal(document)"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                    >
                      <DeleteFileIcon></DeleteFileIcon>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import DocumentsActionModal from "@/components/my-documents/DocumentsActionModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ViewButtonIcon from "@/components/Icons/ViewButton.vue";
import EditButtonIcon from "@/components/Icons/EditButton.vue";
import DeleteFileIcon from "@/components/Icons/DeleteFileButton.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import { mapActions , mapGetters} from "vuex";

export default defineComponent({
  name: "MyDocumentsIndex",
  components: {
    LoadingSpinner,
    DocumentsActionModal,
    DeleteModal,
    ViewButtonIcon,
    EditButtonIcon,
    DeleteFileIcon
  },
  data() {
    return {
      currentlySelectedDocument: {},
      modalTitle: ""
    }
  },
  mounted() {
    if (this.$can("edit_user_profile_info", "all") || this.$route.params.id == this.currentUser.id) {
      setCurrentPageBreadcrumbs("My documents", ["User"]);
      this.fetchData(this.$route.params.id);
    } else {
      this.$can("dashboard_access", "all") ?
          this.$router.push({path: "/"}) :
          this.$router.push({path: "/my-dashboard"});
    }
  },
  methods: {
    ...mapActions("MyDocumentsModule", ["fetchData", "createNew", "update", "destroyData"]),
    openDeleteModal(document) {
      this.currentlySelectedDocument = document;
    },
    openEditModal(document) {
      this.currentlySelectedDocument = document;
      this.modalTitle = "Edit"
    },
    openAddNewModal() {
      this.currentlySelectedDocument = {};
      this.modalTitle = "Upload"
    },
    handleModalSubmit(modalData) {
        if(modalData.action == "Upload") {
          this.createNew(modalData.payload);
        } else{
          this.update({data : modalData.payload, id: this.currentlySelectedDocument.id})
        }
    },
    deleteDocument() {
      this.destroyData(this.currentlySelectedDocument.id).then(() => {
        this.fetchData(this.$route.params.id);
      });
      this.currentlySelectedDocument = {};
    }
  },
  computed: {
    ...mapGetters("MyDocumentsModule" , ["getDocuments", "loading"]),
    ...mapGetters({ currentUser: "currentUser" })
  }
});
</script>
