<template>
  <div>
    <el-select
      name="documentType"
      v-model="inputValue"
      clearable
      size="large"
      placeholder="Document type"
      class="w-100"
    >
      <el-option
        @click="updateValue(item.id)"
        v-for="item in docTypes"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import store from "@/store/index";
import { computed } from "vue";
import { mapActions } from "vuex";

export default {
  props: {
    initialValue: {
      type: [Number, String]
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions("ListsModule" , ["fetchDocumentTypes"])
  },
  mounted() {
    this.fetchDocumentTypes();
  },
  watch: {
    initialValue() {
      this.inputValue = this.initialValue;
    }
  },
  setup(props, { emit }) {
    const docTypes = computed(() => store.getters["ListsModule/documentTypes"]);
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.initialValue
    });
    const updateValue = () => {
      emit("input", inputValue.value);
    };
    return {
      updateValue,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      docTypes
    };
  }
};
</script>
