
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "LoadingSpinner",
  props: {
    spinnerColor: {
      type: String as PropType<string>,
      default: "text-primary"
    }
  }
});
